import React, { useState, useEffect } from 'react';
import './App.css';
import { useNavigate,useParams } from 'react-router-dom';
import { firestore, auth } from './firebase-config';
import { signOut } from 'firebase/auth';
import { doc, setDoc,getDoc,collection, getDocs } from 'firebase/firestore';
import { LogoSVG2, LogoSVG} from './Logosvg';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

function EntryForm() {
    const {jobId} = useParams();
    const user = auth.currentUser;
    const [formData, setFormData] = useState({
        email: user.email,
        name: '',
        age: '',
        yearsOfExperience: '',
        designation: '',
        currentCompany: '',
        resume: null
    });
    const [filename, setFilename] = useState('');
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFormData(prev => ({
                ...prev,
                resume: file
            }));
            setFilename(file.name);
        }
        setFormData(prev => ({
            ...prev,
            resume: file
        }));
        setFilename(file.name);
    };

    const handleLogout = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            console.log("User logged out successfully");
            navigate(`/${jobId}/login`)
        }).catch((error) => {
            // An error happened.
            console.error("Error logging out: ", error);
        });
    };

    const handleSubmit =async (event) => {
        event.preventDefault();
        setIsSubmitting(true); // Start submitting
        const data = new FormData();
        for (const key in formData) {
            data.append(key, formData[key]);
        }
        try{
            const response = await fetch('https://backend-interview-493231910975.asia-south1.run.app/submit-form', {
                method: 'POST',
                body: data,
            });
            const resultData = await response.json();

            if (resultData.message) {
                setIsSubmitting(false);
                alert(resultData.message);
                alert('Extracted Text: ' + resultData.extractedText);
                // await firestore.collection('user_data').doc(user.uid).set(formData);
                const{resume, ...otherData} = formData;
                const saveData = {
                    ...otherData, // Spread all other form data
                    extractedResume: resultData.extractedText // using the extracted text from server
                };
                await setDoc(doc(firestore, 'user_data', user.uid), saveData);
                console.log(saveData);
                navigate(`/${jobId}/interview`);
            } else {
                alert('Error: ' + resultData.errors);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred: ' + error.message);
        }
        setIsSubmitting(false);
    };

    useEffect(() => {
        const resumeExist = async () => {
          const docRef = doc(firestore, 'user_data', user.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            navigate(`/${jobId}/interview`);
          } else {
            console.log("First Time Resume Uploading");
          }
        };
    
        resumeExist();
      }, [jobId]
    );

    return (
        <div className='Appv2'>
            <LogoSVG2/>
            <h5 className='textv2'>Please fill in the deails to Start the interview {user.email}</h5>
            
            <div className='form-container'>
                <form onSubmit={handleSubmit}>
                    <label htmlFor='name'><span className='form-text'>Name</span></label>
                    <input id='name' type="text" name="name" placeholder="CV Raman" /*value={formData.name}*/ onChange={handleInputChange} className="input-field" required />

                    {/* <input type="number" name="age" placeholder="Age" onChange={handleInputChange} className="input-field" required /> */}
                    <label htmlFor='yearsOfExperience'><span className='form-text'>Years of Experience</span></label>
                    <input id='yearsOfExperience' type="number" name="yearsOfExperience" placeholder="Round it of to nearest integer ex: '3'" /*value={formData.yearsOfExperience}*/ onChange={handleInputChange} className="input-field" required />
                    <label htmlFor='designation'><span className='form-text'>Designation at current company</span></label>
                    <input id='designation' type="text" name="designation" placeholder="ex: Scientist | type Fresher if not working" /*value={formData.designation}*/ onChange={handleInputChange} className="input-field" required />
                    <label htmlFor='currentCompany'><span className='form-text'>Current Company</span></label>
                    <input id='currentCompany' type="text" name="currentCompany" placeholder="ex: Google | enter Fresher if not working" /*value={formData.currentCompany}*/ onChange={handleInputChange} className="input-field" required />
                    <div className="file-input">
                        <label htmlFor="file-upload">
                            <FileUploadOutlinedIcon style={{verticalAlign: 'middle', fontSize:'16px', marginRight:'8px'}}/>
                            <span style={{verticalAlign: 'middle', fontSize:'14px'}}>Upload Resume</span>
                        </label>
                        <input id="file-upload" type="file" name="resume" onChange={handleFileChange} required />
                        {filename && <div>Uploaded File: {filename}</div>}
                    </div>
                    <div className='content'>
                        <button className="file-input" style={{cursor:'pointer'}} onClick = {handleLogout}>
                            <span className='textv2'>Log Out</span>
                        </button>
                        <button type="submit" className="submit-button" disabled = {isSubmitting}>
                            {isSubmitting ? 'Processing...' : 'Submit' }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default EntryForm;
